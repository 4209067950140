
import { defineComponent } from 'vue'
import useAutomationRuleOpenModal from '@/compositions/services/useAutomationRuleOpenModal'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'

export default defineComponent({
  components: {
    TmAutoSizePanel,
    TmAutoSizePanelItemButton,
    TmAutoSizePanelMobileActivator,
  },
  setup() {
    const { openAutomationRuleDeleteModal } = useAutomationRuleOpenModal('log')

    return {
      openAutomationRuleDeleteModal,
    }
  },
})
