
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import ServicesAutomationRulesLogsButtons from '@/components/pages/services/automationRules/logs/ServicesAutomationRulesLogsButtons.vue'
import { getTableData } from '@/services/tableService'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import type { AutomationRuleLog } from '@/definitions/services/automationRules/types'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    DetailsTable,
    DetailsTableItem,
    PageBlock,
    ServicesAutomationRulesLogsButtons,
    TmPerson,
    TmCountry,
    TmBadge,
    TmStatus,
  },
  setup() {
    const route = useRoute()

    const log = computed(() => (getTableData('automationLogs') as AutomationRuleLog[])
      .find(el => el.id === route.params.id))

    const breadcrumbsLink = computed<BreadcrumbsLink[]>(() => (log.value)
      ? [
          {
            label: 'Automation rules',
            name: 'base.services.automationRules.rules',
          },
          {
            label: 'Automation log',
            name: 'base.services.automationRules.logs',
          },
        ]
      : [])

    return {
      log,
      breadcrumbsLink,
      formatDate,
    }
  },
})
